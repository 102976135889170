/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.CheckoutAddressTable {
    .CheckoutAddressCard {
        text-align-last: left;
        box-sizing: border-box;
        min-height: 200px;

        @include mobile {
            min-height: auto;
        }

        width: 100%;
        padding: 14px 30px;
        max-width: 525px;
        border: none;

        &-Heading {
            padding-block-end: 6px;
            text-align: start;
            display: flex;
            gap: 5px;

            &-UserName {
                font-size: 16px;
                font-weight: bold;
                line-height: 24px;
                color: var(--primary-blue-color);
                margin-inline-end: 15px;
            }

            &-Status {
                height: 22px;
                background-color: #ddf7fe;
                color: #42444b;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                padding: 3px 14px;
                display: inline-block;
            }
        }

        &-Address {
            text-align: start;
            color: #6c6f7a;
            font-size: 16px;
            line-height: 24px;
        }

        &-Mobile {
            padding-block-start: 11px;

            &-Label {
                color: var(--primary-blue-color);
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                padding-inline-end: 6px;
            }

            &-Value {
                color: #6c6f7a;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
            }
        }
    }

    &-Button {
        cursor: pointer;
        border-radius: 10px;
        //border: 2px solid rgba(22, 126, 191, 0.1);
        margin-block-end: 0px;

        &_isSelected {
            cursor: inherit;
            border: none;
            // border: 2px solid #23336b;
        }
    }
}

.geo-map {
    height: 60px;
    border-radius: 12px;
    background: #FFF5E6;
    display: flex;
    padding: 0px 20px;
    align-items: center;
    margin-block-end: 20px;

    svg {
        margin-inline-end: 5px;
    }

    .text {
        color: #767983;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        font-size: 18px;
    }
}
/* stylelint-disable declaration-no-important */

.CheckoutAddressBook {
    .FieldForm-Fields {
        display: inline !important;
    }

    .FieldGroup {
        display: inline !important;
    }
}

.Field-Wrapper_name_city, .Field-Wrapper_name_region_string, .Field-Wrapper_name_latitude,.Field-Wrapper_name_country_id, .Field-Wrapper_name_longitude {
    display: none;
}

.CheckoutAddressTable-Button:not(.CheckoutAddressTable-Button_isSelected):hover {
    --check-mark-opacity: 1;
    --checkout-address-table-border: transparent;
    --check-mark-color: transparent;
}

.CheckoutAddressBook-Wrapper {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px !important;
}
