/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
/* stylelint-disable declaration-no-important */

.Checkout {
    padding-block-end: 30px;

    @include mobile {
        padding-block-end: 80px;
    }

    @include desktop {
        margin-block-start: 0px;
    }

    @include desktop {
        &::before {
            min-height: 0px;
        }

        &-Header {
            padding-block-start: 0px;
            align-items: flex-start;
            padding-block-end: 0px;
        }
    }

    &-Title {
        font-size: 24px;
        color: #0A0903;
    }

    &-Step {
        font-size: 16px;
        color: #6c6f7a;

        @include desktop {
            padding-block-start: 24px;
        }
    }

    &-SelectedStep,&-StepsBorder,&-TotalSteps {
        font-size: 16px;
    }

    .ExpandableContent-Button {
        align-items: flex-start;

        svg {
            margin-block-start: 25px;
        }
    }

    &-Heading {
        margin-block-start: 24px;
        font-size: 20px;
        text-transform: initial !important;
    }

    &-Wrapper {
        grid-template-columns: none;
    }

    &-InnerWrapper {
        &_multiColumn {
            .Checkout-Wrapper {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }

    &-StickyButtonWrapper {
        z-index: 9 !important;

        @include mobile {
            -webkit-border-before: 1px solid var(--primary-divider-color);
            border-top: 1px solid var(--primary-divider-color);
            position: fixed;
            width: 100%;
            z-index: 85;
            inset-block-end: var(--footer-total-height);
            background-color: var(--color-white);
            padding: 16px;
            margin-block-end: 20px;
        }
    }
}

.address-prompt {
    header {
        display: none;
    }

    main {
        margin: auto;
        min-height: auto;
        border-radius: 24px;
        background: #FFF;
        box-shadow: 0px 2px 34px 0px rgba(0, 0, 0, 0.25);
        padding: 0px;
    }

    .text {
        text-align: center;
        color: #22336A;
        font-weight: 500;
        font-size: 24px;
        line-height: normal;
        box-shadow: 0px 2px 34px 0px rgba(0, 0, 0, 0.25);

        p {
            color: #22336A;
            padding-block-end: 20px;
            font-size: 24px;
            font-weight: 600;
            line-height: 30px;
        }

        button {
            cursor: pointer;
        }
    }

    .button {
        cursor: pointer;
        width: 100%;
        height: 54px;
        border-radius: 12px;
        background: var(--button-background);
        color: #FFF;
        font-size: 18px;
        font-weight: 700;
    }
}

.hio {
    scroll-margin-top: 100px;
}

.CheckoutAddressForm {
    background-color: #EDF2FB;
    padding: 24px 20px;
    border: 1px solid #ADCBE1;
    border-radius: 12px;

    .error {
        border: 2px solid red !important;
    }

    .Field_type_text.Field_isValid input, .Field_type_select.Field_isValid select {
        border: none;
    }
}

.update-address {
    .Field-Wrapper_name_street_0 {
        display: none;
    }

    .googleStreet {
        margin-block-start: 24px;
        color: var(--primary-blue-color);
        font-size: 14px;
        line-height: 16px;
        font-weight: bold;
        display: flex;

        .text {
            color: var(--color-black);
            padding-inline-start: 10px;
        }
    }
}

.geo-alert {
    height: 37px;
    border-radius: 12px;
    background: #FFF5E6;
    display: flex;
    padding: 0px 20px;
    align-items: center;

    @include mobile {
        margin-block-start: 10px;
    }

    svg {
        margin-inline-end: 5px;
    }
}

.CheckoutAddressBook-CustomAddressWrapper {
    margin: 0px 0 30px !important;
}

.CheckoutDeliveryOptions {
    .Checkout-Heading {
        margin-block-start: 30px;
    }
}

.CheckoutAddressTable {
    width: 100%;
    border-radius: 10px;
    margin-block-end: 20px;

    @include mobile {
        margin-block-end: 0px;

        &:last-child {
            margin-block-end: 20px;
        }
    }
}

.CheckoutAddressTable-Button {
    border: 1px solid #D1E4F2 !important;
}

.CheckoutAddressTable-Button.CheckoutAddressTable-Button_isSelected {
    border: 1px solid #23336b !important;
}

.address-part {
    min-height: 146px;

    @include mobile {
        min-height: auto;
    }
}
