/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.CheckoutAddressBook {
    margin: 24px 0;

    @include  desktop {
        margin: 6px 0;
    }

    &-Button {
        color: var(--primary-blue-color);
    }

    #landmarks-placeholder {
        .css-1s2u09g-control {
            border-radius: 10px;
            padding: 5px;
            font-size: 14px;
            border: 1px solid #e2e4e7;

            #react-select-2-placeholder {
                color: #000;
            }
        }

        svg {
            color: #000;
        }
    }

    .css-b62m3t-container {
        &.error-class {
            .css-1s2u09g-control {
                border: 1px solid red;
            }
        }

        .css-1s2u09g-control {
            border-radius: 10px;
            padding: 5px;
            font-size: 14px;
            border: 1px solid #e2e4e7;

            #react-select-2-placeholder {
                color: #000;
            }
        }

        .css-1pahdxg-control {
            border-radius: 10px;
            padding: 5px;
            font-size: 14px;
            border: 1px solid #e2e4e7;

            #react-select-2-placeholder {
                color: #000;
            }
        }

        svg {
            color: #000;
        }
    }
}
